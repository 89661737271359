import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { Map, Marker } from "pigeon-maps"

import { Cover, Section } from "../components"

import icnPin from "../images/contact/icn-pin.svg"

const Contact = () => {
  const renderLocation = (
    city,
    physicalAddress,
    coordinates,
    phoneNumber,
    emailAddress = ""
  ) => (
    <div>
      <div className="physicalLocation">
        <img src={icnPin} alt="Location pin icon" />
        <h2 className="font-style-4">{city} Office</h2>
        <p>{physicalAddress}</p>
      </div>
      <div className="contactDetails">
        <a className="phoneNumber" href={`tel:${phoneNumber}`}>
          {phoneNumber}
        </a>
        {emailAddress && (
          <a className="emailAddress" href={`mailto:${emailAddress}`}>
            {emailAddress}
          </a>
        )}
      </div>
      <div className="map">
        <Map height={325} center={coordinates} zoom={15}>
          <Marker
            width={50}
            anchor={coordinates}
            color="#BA0D14"
            onClick={() => {
              window.open(
                `https://maps.google.com/maps?ll=${coordinates[0]},${coordinates[1]}&z=14&t=m&hl=en-GB&gl=ZA&mapclient=embed&cid=16802434164348671192`,
                "_blank"
              )
            }}
          />
        </Map>
      </div>
    </div>
  )

  return (
    <Wrapper>
      <Cover>
        <div className="textContainer">
          <div className="content">
            <h1>Contact Us</h1>
          </div>
        </div>
        <div className="imageContainer">
          <StaticImage src="../images/contact/img-contact-cover.png" alt="" />
        </div>
      </Cover>
      <Section dark className="offices">
        {renderLocation(
          "Cape Town",
          `Unit 2B, Mainstream Centre, Main Road\nHout Bay, Cape Town,\n7806`,
          [-34.04244424881742, 18.3569772386197],
          "+27 (21) 790 1392",
          "info@red-cap.co.za"
        )}
        {renderLocation(
          "Qgeberha",
          `No.1 A Stanley street, Richmond Hill,\n Qgeberha\n6001`,
          [-33.9586857, 25.611669],
          "+27 (0)87 822 1526"
        )}
      </Section>
    </Wrapper>
  )
}

export default Contact

const Wrapper = styled.div`
  .offices {
    display: grid;
    gap: 52px;
    grid-template-columns: 1fr 1fr;
    margin-top: -125px;
    padding-bottom: 118px;
    padding-top: 215px;

    .physicalLocation {
      img {
        height: auto;
        width: 32px;
      }

      p {
        opacity: 0.8;
        white-space: pre-line;

        @media screen and (max-width: ${(props) =>
            props.theme.breakpoints.xs}) {
          font-size: var(--fontSize-root-sm);
          line-height: var(--lineHeight-root-sm);
        }
      }
    }

    .contactDetails {
      display: grid;
      grid-template-rows: 60px 34px;
      margin-bottom: 30px;

      a {
        display: block;
        margin: 10px 0 20px;

        &.phoneNumber {
          font-family: "Gilroy", var(--fontFamily-sans);
          font-size: 38px;
          font-weight: 600;
          line-height: 34px;
        }

        &.emailAddress {
          color: #d1232a;
        }
      }
    }

    .map {
      cursor: pointer;
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.xs}) {
    .offices {
      .physicalLocation {
        .font-style-4 {
          font-size: 20px;
          line-height: 26px;
          margin: 10px 0 14px 0;
        }

        p {
          margin: 0 0 10px 0;
        }
      }

      .contactDetails {
        grid-template-rows: 40px auto;

        a {
          margin: 5px 0;

          &.phoneNumber {
            font-size: 30px;
          }
        }
      }
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    .offices {
      gap: 74px;
      grid-template-columns: 1fr;
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.xl}) {
    .textContainer {
      background-color: #f3f3f3;
    }

    .offices {
      margin-top: 0;
      padding-top: 0;
    }
  }

  @media screen and (min-width: ${(props) => props.theme.minBreakpoints.xxl}) {
    .offices {
      padding-left: 100px;
      padding-right: 100px;
    }
  }
`
